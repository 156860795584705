import { watch, ref, unref } from 'vue'
import { formatSequence } from '@tenant/utils/helper'

/**
 * @param {string|Ref<unknown>} nextNumber
 * @param {string|Ref<unknown>} sequenceFormatText
 */
export const useSequence = (nextNumber, sequenceFormatText) => {
    const preview = ref('')

    watch([sequenceFormatText, nextNumber], () => {
        preview.value = formatSequence(
            unref(nextNumber),
            unref(sequenceFormatText)
        )
    })

    return {
        preview,
    }
}
